import { combineReducers } from 'redux'
import code, { CodeAction } from './code'
import flow, { FlowAction } from './flow'
import dnd, { DndAction } from './dnd'

const rootReducer = combineReducers({ code, flow, dnd })
export default rootReducer

export type RootState = ReturnType<typeof rootReducer>

export type Action = CodeAction | FlowAction | DndAction
