import React from 'react'

// The arrowhead for a horizontal arrow.
export function Arrowhead({
  xStem,
  xTip,
  y,
  thickness,
}: {
  xStem: number
  xTip: number
  y: number
  thickness: number
}): JSX.Element {
  const points = [
    [xTip, y],
    [xStem, y + 0.5 * thickness],
    [xStem, y - 0.5 * thickness],
  ]

  const pointsStr = points.map((point) => point.join(',')).join(' ')

  return <polygon points={pointsStr} />
}

export interface LineOptions {
  strokeWidth: number
  style: any
}

export interface ArrowheadOptions {
  length: number
  thickness: number
}

export const DEFAULT_ARROWHEAD_OPTIONS: ArrowheadOptions = {
  length: 15,
  thickness: 9.25,
}

// A horizontal arrow.
export default function Arrow({
  xFrom,
  xTo,
  y,
  line: lineOptions,
  arrowhead = DEFAULT_ARROWHEAD_OPTIONS,
  bidirectional = false,
}: {
  xFrom: number
  xTo: number
  y: number
  line?: LineOptions
  arrowhead?: ArrowheadOptions
  bidirectional?: boolean
}): JSX.Element {
  const xStemTo = xTo > xFrom ? xTo - arrowhead.length : xTo + arrowhead.length
  const xStemFrom =
    xTo > xFrom ? xFrom + arrowhead.length : xFrom - arrowhead.length

  return (
    <g style={{ cursor: 'move' }}>
      <line x1={xFrom} y1={y} x2={xTo} y2={y} {...lineOptions} />
      <Arrowhead xStem={xStemTo} xTip={xTo} y={y} {...arrowhead} />
      {bidirectional ? (
        <Arrowhead xStem={xStemFrom} xTip={xFrom} y={y} {...arrowhead} />
      ) : null}
    </g>
  )
}

// An rectangular arrow that points back to self.
export function SelfArrow({
  x,
  yFrom,
  width,
  height,
  line: lineOptions,
  arrowhead = DEFAULT_ARROWHEAD_OPTIONS,
}: {
  x: number
  yFrom: number
  width: number
  height: number
  line: LineOptions
  arrowhead: ArrowheadOptions
}) {
  const xMin = x
  const xMax = x + width
  const yMin = yFrom
  const yMax = yFrom + height

  return (
    <g>
      <line x1={xMin} y1={yMin} x2={xMax} y2={yMin} {...lineOptions} />
      <line x1={xMax} y1={yMin} x2={xMax} y2={yMax} {...lineOptions} />
      <Arrow
        xFrom={xMax}
        xTo={xMin}
        y={yMax}
        line={lineOptions}
        arrowhead={arrowhead}
      />
    </g>
  )
}
