import yaml from 'js-yaml'
import SequentialFlow, {
  SequentialFlowData,
} from '../../component/sequential-flow'
import { updateCode } from './code'
import { SIMULATE_EDGE_DROP, FINISH_EDGE_DROP } from './dnd'
import { Action } from '.'

export const UPDATE_FLOW = 'UPDATE_FLOW'
export const UPDATE_NODE_LABEL = 'UPDATE_NODE_LABEL'
export const UPDATE_EDGE_LABEL = 'UPDATE_EDGE_LABEL'

export type FlowAction =
  | {
      type: typeof UPDATE_FLOW
      flow: SequentialFlowData
    }
  | {
      type: typeof UPDATE_NODE_LABEL
      index: number
      value: string
    }
  | {
      type: typeof UPDATE_EDGE_LABEL
      index: number
      value: string
    }

export const updateFlow = (flow: SequentialFlowData): Action => ({
  type: UPDATE_FLOW,
  flow,
})

export const updateNodeLabel = (index: number, value: string) => (
  dispatch: (action: Action) => void,
  getState: any
) => {
  dispatch({ type: UPDATE_NODE_LABEL, index, value })

  const code = yaml.safeDump(getState().flow)

  // @ts-expect-error
  dispatch(updateCode(code, false))
}

export const updateEdgeLabel = (index: number, value: string) => (
  dispatch: (action: Action) => void,
  getState: any
) => {
  dispatch({ type: UPDATE_EDGE_LABEL, index, value })

  const code = yaml.safeDump(getState().flow)

  // @ts-expect-error
  dispatch(updateCode(code, false))
}

type State = SequentialFlowData | null

const reducer = (state: State = null, action: Action) => {
  if (action.type === UPDATE_FLOW) {
    return action.flow
  } else if (action.type === UPDATE_NODE_LABEL) {
    if (state !== null) {
      return SequentialFlow.create(state)
        .updateNodeLabel(action.index, action.value)
        .toNative()
    }
  } else if (action.type === UPDATE_EDGE_LABEL) {
    const newFlow = Object.assign({}, state)
    newFlow.edges = newFlow.edges.slice()
    newFlow.edges[action.index] = Object.assign({}, newFlow.edges[action.index])
    newFlow.edges[action.index].label = action.value
    return newFlow
  } else if (
    action.type === SIMULATE_EDGE_DROP ||
    action.type === FINISH_EDGE_DROP
  ) {
    return SequentialFlow.create(action.initialFlow)
      .moveEdge(action.fromIndex, action.toIndex)
      .toNative()
  } else {
    return state
  }
}
export default reducer
