import _ from 'underscore'

export interface Edge {
  from: string
  label: string
  to: string
  bidirectional?: boolean
}

export interface SequentialFlowData {
  edges: Edge[]
  nodes?: string[]
  layout?: {
    nodeSpacing: number
    edgeSpacing: number
  }
  version: string
}

// Represent the contents of a sequential flow diagram.
export default class SequentialFlow {
  version: string
  nodes: string[]
  edges: Edge[]

  constructor(data: SequentialFlowData) {
    if (data.version !== '1.0') {
      throw Error(`Supported version is 1.0, got ${data.version}`)
    }
    this.version = data.version

    this.edges = data.edges.map((edge) => Object.assign({}, edge))

    this.nodes = data.nodes || SequentialFlow._inferNodeOrder(data.edges)
  }

  static create(data: SequentialFlowData) {
    return new SequentialFlow(data)
  }

  // Automatically infer the order of appearance of the nodes.
  //
  // Return an array of the node labels, in order.
  //
  static _inferNodeOrder(edges: Edge[]): string[] {
    return _(edges)
      .chain()
      .map((edge) => [edge.from, edge.to])
      .flatten()
      .uniq()
      .value()
  }

  toNative(): SequentialFlowData {
    const result: SequentialFlowData = {
      version: this.version,
      edges: this.edges.slice(),
    }

    // Preserve our node order.
    const inferredNodeOrder = SequentialFlow._inferNodeOrder(this.edges)
    if (!_.isEqual(this.nodes, inferredNodeOrder)) {
      result.nodes = this.nodes.slice()
    }

    return result
  }

  updateNodeLabel(index: number, newLabel: string) {
    const oldLabel = this.nodes[index]

    this.edges.forEach((edge) => {
      if (edge.from === oldLabel) {
        edge.from = newLabel
      }
      if (edge.to === oldLabel) {
        edge.to = newLabel
      }
    })

    this.nodes[index] = newLabel

    return this
  }

  moveEdge(fromIndex: number, toIndex: number) {
    const [movedEdge] = this.edges.splice(fromIndex, 1)
    this.edges.splice(toIndex, 0, movedEdge)

    return this
  }
}
