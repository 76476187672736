import * as bunyan from 'browser-bunyan'

export default function createDefaultLogger() {
  return bunyan.createLogger({
    name: 'myLogger',
    streams: [
      {
        level: 'info',
        stream: new bunyan.ConsoleFormattedStream(),
      },
    ],
    serializers: bunyan.stdSerializers,
    src: true,
  })
}
