import React from 'react'
// @ts-expect-error
import EditableSvgLabel from 'react-editable-svg-label'
// @ts-expect-error
import { Layoutable as layoutable, LayoutRect } from 'react-flexbox-svg'
import { useDrop } from 'react-dnd'
import Arrow, { SelfArrow } from './arrow'
import { LineOptions, ArrowheadOptions } from './primitive-arrow'

const DEFAULT_DIMENSIONS = {
  selfArrow: { width: 30, height: 30 },
  arrow: { height: 15 },
  margin: 15,
}

function computeStyleFromProps(props: any) {
  const { selfArrow, arrow, margin } = DEFAULT_DIMENSIONS

  const commonStyle = { marginTop: margin, marginBottom: margin }

  const { xFrom, xTo } = props

  return xFrom === xTo
    ? Object.assign(commonStyle, { height: selfArrow.height + 12.5 })
    : Object.assign(commonStyle, { height: arrow.height })
}

interface LayoutableInfo {
  layout: any
}

export function Edge({
  index,
  xFrom,
  xTo,
  label,
  bidirectional,
  line: lineOptions,
  arrowhead,
  text,
  onLabelUpdate,
  onBeginDrag,
  onSimulateDrop,
  onFinishDrop,
  layout,
}: {
  index: number
  xFrom: number
  xTo: number
  label: string
  bidirectional: boolean
  line: LineOptions
  arrowhead: ArrowheadOptions
  text: {
    bottomMargin: number
    fontSize: string
    letterSpacing: string
  }
  onLabelUpdate: (value: string) => void
  onBeginDrag: () => void
  onSimulateDrop: (index: number) => void
  onFinishDrop: () => void
} & LayoutableInfo): JSX.Element {
  const [, drop] = useDrop({
    accept: 'EDGE',
    hover() {
      onSimulateDrop(index)
    },
  })

  const commonArrowProps = { line: lineOptions, arrowhead }

  const commonLabelProps = {
    fontSize: text.fontSize,
    letterSpacing: text.letterSpacing,
    focusOnOpen: true,
    onChange: onLabelUpdate,
  }

  if (xFrom === xTo) {
    const y = 7.5
    const { width, height } = DEFAULT_DIMENSIONS.selfArrow

    const textX = xFrom + width + 10
    const textY = y + height / 2

    return (
      <g key={index}>
        <g ref={drop}>
          <LayoutRect layout={layout} />
        </g>

        <SelfArrow
          {...commonArrowProps}
          x={xFrom}
          yFrom={y}
          width={width}
          height={height}
        />

        <EditableSvgLabel {...commonLabelProps} x={textX} y={textY}>
          {label}
        </EditableSvgLabel>
      </g>
    )
  }

  const y = DEFAULT_DIMENSIONS.arrow.height / 2

  const textX = (xTo + xFrom) / 2
  const textY = y - text.bottomMargin

  return (
    <g key={index}>
      <g ref={drop}>
        <LayoutRect layout={layout} />
      </g>

      <Arrow
        {...commonArrowProps}
        dragItemIndex={index}
        onBeginDrag={onBeginDrag}
        xFrom={xFrom}
        xTo={xTo}
        y={y}
        bidirectional={bidirectional}
      />

      <EditableSvgLabel
        {...commonLabelProps}
        x={textX}
        y={textY}
        textAnchor="middle"
      >
        {label}
      </EditableSvgLabel>
    </g>
  )
}

const LayoutableEdge = layoutable(computeStyleFromProps, {
  layoutProp: 'layout',
})(Edge)
export default LayoutableEdge
