import React from 'react'
import { useDrag } from 'react-dnd'
import PrimitiveArrow, {
  Arrowhead,
  DEFAULT_ARROWHEAD_OPTIONS,
  LineOptions,
  ArrowheadOptions,
} from './primitive-arrow'

export interface DragItem {
  type: 'EDGE'
  index: number
}

// A horizontal arrow.
export default function Arrow({
  dragItemIndex,
  onBeginDrag,
  xFrom,
  xTo,
  y,
  line: lineOptions,
  arrowhead = DEFAULT_ARROWHEAD_OPTIONS,
  bidirectional,
}: {
  dragItemIndex: number
  onBeginDrag: () => void
  xFrom: number
  xTo: number
  y: number
  line?: LineOptions
  arrowhead?: ArrowheadOptions
  bidirectional?: boolean
}): JSX.Element {
  const [, drag] = useDrag({
    item: { type: 'EDGE', index: dragItemIndex },
    begin() {
      onBeginDrag()
    },
  })

  const xStemTo = xTo > xFrom ? xTo - arrowhead.length : xTo + arrowhead.length
  const xStemFrom =
    xTo > xFrom ? xFrom + arrowhead.length : xFrom - arrowhead.length

  return (
    <g ref={drag} style={{ cursor: 'move' }}>
      <line
        x1={xFrom}
        y1={y}
        x2={xTo}
        y2={y}
        style={{ stroke: 'transparent' }}
        strokeWidth="7"
      />
      <line x1={xFrom} y1={y} x2={xTo} y2={y} {...lineOptions} />
      <Arrowhead xStem={xStemTo} xTip={xTo} y={y} {...arrowhead} />
      {bidirectional ? (
        <Arrowhead xStem={xStemFrom} xTip={xFrom} y={y} {...arrowhead} />
      ) : null}
    </g>
  )
}

// An rectangular arrow that points back to self.
export function SelfArrow({
  x,
  yFrom,
  width,
  height,
  line: lineOptions,
  arrowhead = DEFAULT_ARROWHEAD_OPTIONS,
}: {
  x: number
  yFrom: number
  width: number
  height: number
  line?: LineOptions
  arrowhead?: ArrowheadOptions
}) {
  const xMin = x
  const xMax = x + width
  const yMin = yFrom
  const yMax = yFrom + height

  return (
    <g>
      <line x1={xMin} y1={yMin} x2={xMax} y2={yMin} {...lineOptions} />
      <line x1={xMax} y1={yMin} x2={xMax} y2={yMax} {...lineOptions} />
      <PrimitiveArrow
        xFrom={xMax}
        xTo={xMin}
        y={yMax}
        line={lineOptions}
        arrowhead={arrowhead}
      />
    </g>
  )
}
