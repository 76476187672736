import React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import { connect } from 'react-redux'
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/yaml/yaml'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/lint.css'
import saveSvgAsPng from 'save-svg-as-png'
import Diagram from '../component/diagram'
import createDefaultLogger from './logger'
import loadSamples from './samples'
import { updateCode } from './state/code'
import { updateNodeLabel, updateEdgeLabel } from './state/flow'
import { beginEdgeDrag, simulateEdgeDrop, finishEdgeDrop } from './state/dnd'
import './main.css'

const GlobalStyle = createGlobalStyle`
  @import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700);
`

export class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      samples: [],
    }

    this.logger = createDefaultLogger()
    this.diagram = React.createRef()
    this.formatLintErrors = this.formatLintErrors.bind(this)
    this.handleSelectedSampleChanged = this.handleSelectedSampleChanged.bind(
      this
    )
    this.handleLoadSelectedSample = this.handleLoadSelectedSample.bind(this)
    this.handleCodeChanged = this.handleCodeChanged.bind(this)
    this.handleExportDiagramAsSvg = this.handleExportDiagramAsSvg.bind(this)
    this.handleExportDiagramAsPng = this.handleExportDiagramAsPng.bind(this)
  }

  componentDidMount() {
    loadSamples()
      .then((samples) => {
        this.setState({ samples })
        this.props.onCodeUpdate(samples[0].contents)
      })
      .catch((err) => {
        this.logger.warn('Error loading samples: ', err)
      })
  }

  formatLintErrors() {
    const codeError = this.props.code.error

    return codeError
      ? [
          {
            message: codeError.message,
            from: { line: codeError.line, ch: 0 },
            to: { line: codeError.line, ch: codeError.column + 1 },
          },
        ]
      : []
  }

  handleSelectedSampleChanged(event) {
    this.setState({ selectedSample: event.target.value })
  }

  handleLoadSelectedSample() {
    const sample = this.state.samples.find(
      (sampleInfo) => sampleInfo.id === this.state.selectedSample
    )

    this.props.onCodeUpdate(sample.contents)
  }

  handleCodeChanged(editor, data, value) {
    this.props.onCodeUpdate(value)
  }

  handleExportDiagramAsSvg() {
    saveSvgAsPng.saveSvg(this.diagram.current, 'diagram.svg')
  }

  handleExportDiagramAsPng() {
    saveSvgAsPng.saveSvgAsPng(this.diagram.current, 'diagram.png', {
      backgroundColor: 'white',
      scale: 2,
    })
  }

  _renderStatusIndicator() {
    const codeError = this.props.code.error

    return codeError ? (
      <div className="code-status code-status-error">
        Status: Unable to parse.
      </div>
    ) : (
      <div className="code-status code-status-ok">Status: Up to date.</div>
    )
  }

  _renderSampleLoader() {
    return (
      <div>
        <select
          onChange={this.handleSelectedSampleChanged}
          disabled={!this.state.samples.length}
        >
          <option value="">(Choose a sample)</option>
          {this.state.samples.map((sampleInfo) => (
            <option key={sampleInfo.id} value={sampleInfo.id}>
              {sampleInfo.name}
            </option>
          ))}
        </select>
        <button
          disabled={!this.state.selectedSample}
          onClick={this.handleLoadSelectedSample}
        >
          Load
        </button>
      </div>
    )
  }

  render() {
    const codeMirrorOptions = {
      mode: 'yaml',
      lineNumbers: true,
      lint: this.formatLintErrors,
      gutters: ['CodeMirror-lint-markers'],
    }

    const diagram = this.props.flow ? (
      <Diagram
        ref={this.diagram}
        flow={this.props.flow}
        onNodeLabelUpdate={this.props.onNodeLabelUpdate}
        onEdgeLabelUpdate={this.props.onEdgeLabelUpdate}
        onBeginEdgeDrag={this.props.onBeginEdgeDrag}
        onSimulateEdgeDrop={this.props.onSimulateEdgeDrop}
        onFinishEdgeDrop={this.props.onFinishEdgeDrop}
      />
    ) : null

    return (
      <div>
        <GlobalStyle />
        <CodeMirror
          value={this.props.code.source}
          onBeforeChange={this.handleCodeChanged}
          options={codeMirrorOptions}
        />
        {this._renderStatusIndicator()}
        {this._renderSampleLoader()}
        {diagram}
        <button
          disabled={!this.props.flow}
          onClick={this.handleExportDiagramAsPng}
        >
          Export as PNG
        </button>
        <button
          disabled={!this.props.flow}
          onClick={this.handleExportDiagramAsSvg}
        >
          Export as SVG
        </button>
      </div>
    )
  }
}

App.propTypes = {
  code: PropTypes.object,
  flow: PropTypes.object,
  onCodeUpdate: PropTypes.func.isRequired,
  onNodeLabelUpdate: PropTypes.func.isRequired,
  onEdgeLabelUpdate: PropTypes.func.isRequired,
  onBeginEdgeDrag: PropTypes.func.isRequired,
  onSimulateEdgeDrop: PropTypes.func.isRequired,
  onFinishEdgeDrop: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({
  onCodeUpdate: (code) => {
    dispatch(updateCode(code))
  },
  onNodeLabelUpdate: (index, value) => {
    dispatch(updateNodeLabel(index, value))
  },
  onEdgeLabelUpdate: (index, value) => {
    dispatch(updateEdgeLabel(index, value))
  },
  onBeginEdgeDrag: (fromIndex) => {
    dispatch(beginEdgeDrag(fromIndex))
  },
  onSimulateEdgeDrop: (toIndex) => {
    dispatch(simulateEdgeDrop(toIndex))
  },
  onFinishEdgeDrop: (toIndex) => {
    dispatch(finishEdgeDrop(toIndex))
  },
})

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App)

export default AppContainer
