import yaml from 'js-yaml'
import { combineReducers } from 'redux'
import { updateFlow } from './flow'
import { Action } from '.'
import { SequentialFlowData } from '../../component/sequential-flow'

export const UPDATE_CODE = 'UPDATE_CODE'
export const SET_CODE_ERROR = 'SET_CODE_ERROR'
export const CLEAR_CODE_ERROR = 'CLEAR_CODE_ERROR'

export type CodeAction =
  | { type: typeof CLEAR_CODE_ERROR }
  | {
      type: typeof SET_CODE_ERROR
      error: Error
      line: number
      column: number
      message: string
    }
  | { type: typeof UPDATE_CODE; code: string }

export const setCodeError = (
  error: Error,
  line: number,
  column: number,
  message: string
): Action => ({
  type: SET_CODE_ERROR,
  error,
  line,
  column,
  message,
})

export const clearCodeError = (): Action => ({ type: CLEAR_CODE_ERROR })

// Use `shouldPropagate: true` for user edits of the code, and
// `shouldPropagate: false` for programmatic updates based on in-place edits,
// drag & drop, etc., when the flow has already been updated.
export const updateCode = (code: string, shouldPropagate = true) => (
  dispatch: (action: Action) => void
) => {
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  dispatch({ type: UPDATE_CODE, code })

  if (shouldPropagate) {
    let flowData: SequentialFlowData

    try {
      flowData = yaml.safeLoad(code) as SequentialFlowData
    } catch (err) {
      dispatch(
        setCodeError(
          err,
          err.mark.line,
          err.mark.column,
          capitalize(err.reason)
        )
      )
      return
    }

    dispatch(clearCodeError())
    dispatch(updateFlow(flowData))
  } else {
    dispatch(clearCodeError())
  }
}

const source = (state = '', action: Action) =>
  action.type === UPDATE_CODE ? action.code : state

const error = (state = null, action: Action) => {
  if (action.type === SET_CODE_ERROR) {
    return {
      error: action.error,
      line: action.line,
      column: action.column,
      message: action.message,
    }
  } else if (action.type === CLEAR_CODE_ERROR) {
    return null
  } else {
    return state
  }
}

const reducer = combineReducers({
  source,
  error,
})
export default reducer
