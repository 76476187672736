import React, { useMemo } from 'react'
// @ts-expect-error
import EditableSvgLabel from 'react-editable-svg-label'
// @ts-expect-error
import { FlexContext, FlexContainer } from 'react-flexbox-svg'
import SequentialFlow, {
  Edge as EdgeData,
  SequentialFlowData,
} from './sequential-flow'
import Edge from './edge'
import { LineOptions, ArrowheadOptions } from './primitive-arrow'

const Diagram = React.forwardRef(function Diagram(
  {
    flow: flowData,
    headerHeight = 128,
    horizontalMargin = 30,
    verticalMargin = 15,
    nodes = {
      width: 145,
      label: {
        topMargin: 0,
        rightMargin: 5,
        fontSize: '0.8em',
        letterSpacing: '0.02em',
      },
      line: {
        strokeWidth: 1,
        style: { stroke: '#ccc' },
      },
    },
    edges = {
      height: 45,
      text: {
        bottomMargin: 6,
        fontSize: '0.7em',
        letterSpacing: '0.02em',
      },
      line: {
        strokeWidth: 1,
        style: { stroke: 'black' },
      },
    },
    width,
    height,
    onNodeLabelUpdate,
    onEdgeLabelUpdate,
    onBeginEdgeDrag,
    onSimulateEdgeDrop,
    onFinishEdgeDrop,
  }: {
    flow: SequentialFlowData
    headerHeight?: number
    horizontalMargin?: number
    verticalMargin?: number
    nodes?: {
      width: number
      label: {
        topMargin: number
        rightMargin: number
        fontSize: string
        letterSpacing: string
      }
      line: {
        strokeWidth: number
        style: any
      }
    }
    edges?: {
      height: number
      text: {
        bottomMargin: number
        fontSize: string
        letterSpacing: string
      }
      line: LineOptions
      arrowhead?: ArrowheadOptions
    }
    width?: number
    height?: number
    onNodeLabelUpdate: (index: number, value: string) => void
    onEdgeLabelUpdate: (index: number, value: string) => void
    onBeginEdgeDrag: (index: number) => void
    onSimulateEdgeDrop: (index: number) => void
    onFinishEdgeDrop: (index: number) => void
  },
  ref:
    | ((instance: SVGSVGElement | null) => void)
    | React.MutableRefObject<SVGSVGElement | null>
    | null
): JSX.Element {
  const flow = useMemo(() => new SequentialFlow(flowData), [flowData])
  const selfArrow = {
    width: nodes.width / 3,
    height: (edges.height * 2) / 3,
    bottomMargin: (edges.height * 2) / 3,
  }

  // The height of the index'th node.
  function heightOfEdge(index: number) {
    const thisEdge = flow.edges[index]

    return thisEdge.from === thisEdge.to
      ? selfArrow.height + selfArrow.bottomMargin
      : edges.height
  }

  function totalHeightOfEdges() {
    let height = 0

    for (let i = 0; i < flow.edges.length; ++i) {
      height += heightOfEdge(i)
    }

    return height
  }
  if (height === undefined) {
    height = headerHeight + 2 * verticalMargin + totalHeightOfEdges()
  }
  if (width === undefined) {
    width = 2 * horizontalMargin + (flow.nodes.length - 1) * nodes.width
  }

  // The x position of the index'th node.
  function horizonalPositionOfNode(index: number) {
    return horizontalMargin + index * nodes.width
  }

  // The y position of the index'th edge.
  // function verticalPositionOfEdge(index: number) {
  //   // This is pretty inefficient.
  //   let position = headerHeight + verticalMargin

  //   for (let i = 0; i < index; ++i) {
  //     position += heightOfEdge(i)
  //   }

  //   return position
  // }

  function renderNode(name: string, index: number): JSX.Element {
    function renderLine(index: number) {
      const x = horizonalPositionOfNode(index)

      return <line x1={x} y1={0} x2={x} y2={height} {...nodes.line} />
    }

    function renderLabel(name: string, index: number) {
      const x = horizonalPositionOfNode(index) - nodes.label.rightMargin

      const y = headerHeight / 2

      return (
        <g clipPath="url(#node-label)">
          <EditableSvgLabel
            x={x}
            y={y}
            textAnchor="middle"
            fontSize={nodes.label.fontSize}
            letterSpacing={nodes.label.letterSpacing}
            transform={['rotate(-90 ', x, y, ')'].join(' ')}
            focusOnOpen
            onChange={(value: string) => onNodeLabelUpdate(index, value)}
          >
            {name}
          </EditableSvgLabel>
        </g>
      )
    }

    return (
      <g key={index}>
        {renderLine(index)}
        {renderLabel(name, index)}
      </g>
    )
  }

  function renderEdge(edge: EdgeData, index: number) {
    const { nodes } = flow

    const xFrom = horizonalPositionOfNode(nodes.indexOf(edge.from))
    const xTo = horizonalPositionOfNode(nodes.indexOf(edge.to))

    return (
      <Edge
        style={{ height: 200, width: 200 }}
        key={index}
        index={index}
        xFrom={xFrom}
        xTo={xTo}
        label={edge.label}
        bidirectional={edge.bidirectional ?? false}
        line={edges.line}
        arrowhead={edges.arrowhead}
        text={edges.text}
        onLabelUpdate={(value: string) => onEdgeLabelUpdate(index, value)}
        onBeginDrag={() => onBeginEdgeDrag(index)}
        onSimulateDrop={() => onSimulateEdgeDrop(index)}
        onFinishDrop={() => onFinishEdgeDrop(index)}
      />
    )
  }

  // Need to embed this here for PNG exports to display the correct font.
  const embedCss =
    '@font-face {' +
    "  font-family: 'Source Sans Pro';" +
    '  font-style: normal;' +
    '  font-weight: 400;' +
    "  src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlJbPFduIYtoLzwST68uhz_Y.woff2) format('woff2');" +
    '  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, ' +
    '    U+2212, U+2215;' +
    '}' +
    "text { font-family: 'Source Sans Pro' }"

  const containerStyle = {
    width,
    flexDirection: 'column',
    marginTop: headerHeight,
  }

  return (
    <div>
      <svg width={width} height={height} ref={ref}>
        <style>{embedCss}</style>

        <clipPath id="node-label">
          <rect x="0" y="0" width={width} height={headerHeight} />
        </clipPath>

        <g id="nodes">
          {flow.nodes.map((name, index) => renderNode(name, index))}
        </g>

        <FlexContext>
          <FlexContainer id="edges" style={containerStyle}>
            {flow.edges.map((edge, index) => renderEdge(edge, index))}
          </FlexContainer>
        </FlexContext>
      </svg>
    </div>
  )
})
export default Diagram
