import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import rootReducer from './app/state/index'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { DndProvider } from 'react-dnd'
import MouseBackEnd from 'react-dnd-mouse-backend'
import App from './app/app'

const store = createStore(rootReducer, applyMiddleware(thunk, createLogger()))

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={MouseBackEnd}>
      <App />
    </DndProvider>
  </Provider>,
  document.getElementById('root')
)
