const SAMPLE_INFO = [
  {
    id: 'restaurant',
    name: 'Restaurant',
  },
  {
    id: 'ssh-password',
    name: 'SSH Password Authentication',
  },
  {
    id: 'deploy',
    name: 'Test automation',
  },
]

const loadSamples = () => {
  const resolved = SAMPLE_INFO.map((sample) =>
    fetch(`samples/${sample.id}.yml`)
      .then((data) => data.text())
      .then((text) => Object.assign({ contents: text }, sample))
  )

  return Promise.all(resolved)
}

export default loadSamples
