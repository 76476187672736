import yaml from 'js-yaml'
import SequentialFlow from '../../component/sequential-flow'
import { updateCode } from './code'
import { Action } from '.'

export const BEGIN_EDGE_DRAG = 'BEGIN_EDGE_DRAG'
export const SIMULATE_EDGE_DROP = 'SIMULATE_EDGE_DROP'
export const FINISH_EDGE_DROP = 'FINISH_EDGE_DROP'

export type DndAction =
  | {
      type: typeof BEGIN_EDGE_DRAG
      fromIndex: number
      initialFlow: SequentialFlow
    }
  | {
      type: typeof SIMULATE_EDGE_DROP
      initialFlow: SequentialFlow
      fromIndex: number
      toIndex: number
    }
  | {
      type: typeof FINISH_EDGE_DROP
      initialFlow: SequentialFlow
      fromIndex: number
      toIndex: number
    }

export const beginEdgeDrag = (fromIndex: number) => (
  dispatch: (action: Action) => void,
  getState: any
) => {
  dispatch({ type: BEGIN_EDGE_DRAG, initialFlow: getState().flow, fromIndex })
}

export const simulateEdgeDrop = (toIndex: number) => (
  dispatch: (action: Action) => void,
  getState: any
) => {
  dispatch({
    type: SIMULATE_EDGE_DROP,
    initialFlow: getState().dnd.initialFlow,
    fromIndex: getState().dnd.fromIndex,
    toIndex,
  })
}

export const finishEdgeDrop = (toIndex: number) => (
  dispatch: (action: Action) => void,
  getState: any
) => {
  dispatch({
    type: FINISH_EDGE_DROP,
    initialFlow: getState().dnd.initialFlow,
    fromIndex: getState().dnd.fromIndex,
    toIndex,
  })

  const code = yaml.safeDump(getState().flow)

  // @ts-expect-error
  dispatch(updateCode(code, false))
}

export interface State {
  fromIndex?: number
  initialFlow?: number
}

export default function reducer(state: State = {}, action: Action) {
  if (action.type === BEGIN_EDGE_DRAG) {
    return {
      fromIndex: action.fromIndex,
      initialFlow: action.initialFlow,
    }
  } else if (action.type === FINISH_EDGE_DROP) {
    return {}
  } else {
    return state
  }
}
